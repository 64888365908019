<template>
  <div class="shared-index">
    <el-row
      class="table-title"
      type="flex"
      align="center"
      justify="space-between"
    >
      <div class="table-title__left">
        <el-tabs v-model="activeTab" type="card">
          <el-tab-pane
            v-for="title in titles"
            :key="title.index"
            :label="pageTitle(title.value)"
            :name="title.value"
          ></el-tab-pane>
        </el-tabs>
        <div
          v-for="button in tableHeaderActions"
          :key="button.key"
          class="mr-2"
        >
          <synchronize-button
            v-if="button.key === 'synchronize'"
            size="small"
            :disabled="loading"
            :data="tableData"
            :type="type"
          />
          <import-excel-button
            v-else-if="button.key === 'import'"
            size="small"
            :disabled="loading"
            :total="total"
            :columns="excelColumns"
            :type="type"
            :titles="titles"
            :active-tab="activeTab"
            :detail="detail"
            :children="children"
            @reload="handleReloadDada"
          />
          <export-excel-button
            v-else-if="button.key === 'export'"
            size="small"
            :disabled="loading || total === 0"
            :order="order"
            :total="total"
            :columns="excelColumns"
            :type="type"
            :titles="titles"
            :active-tab="activeTab"
            :detail="detail"
            :children="children"
          />
          <el-button
            v-else
            size="small"
            :type="button.type"
            :icon="button.icon"
            :disabled="loading"
            @click="handleHeaderAction(button)"
          >
            {{ button.label }}
          </el-button>
        </div>
      </div>
      <div v-if="filterable" class="table-title__right">
        <el-input
          v-model="searchText"
          suffix-icon="el-icon-search"
          placeholder="Tìm kiếm theo tên"
          size="small"
          :disabled="loading"
          @keyup.enter.native="handleSearchText"
        ></el-input>
      </div>
    </el-row>
    <div class="table">
      <el-table
        v-loading="loading"
        style="width: 100%"
        :data="tableData"
        :sort-orders="['ascending', 'descending', '']"
        @sort-change="handleSortData"
      >
        <el-table-column
          v-for="column in tableColumns"
          :key="column.key"
          :label="column.label"
          :width="column.width"
          :align="column.align"
          :sortable="column.sortable"
          :prop="column.key"
        >
          <template slot-scope="scope">
            <table-column
              v-if="column.key !== 'actions'"
              :type="type"
              :column="column"
              :row="scope.row"
              :language="language"
            />
            <shared-actions
              v-else
              :data="scope.row"
              :type="type"
              :actions="actions"
              :table-data="tableData"
              :router-data="$route.meta.data"
              :page="page"
              @showQR="() => qrVisible = true"
              @reloadPage="() => loadDataOfPage()"
              @changePage="(v) => page = v"
              @reloadConstants="() => handleReloadConstant()"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-row type="flex" align="middle" justify="end" class="pagination">
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 20, 30]"
        :page-size.sync="limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleChangeSize"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <qrcode-popup
      :visible="qrVisible"
      :row="selectedRow"
      @close="qrVisible = false"
    />
    <create-item-popup
      v-if="createVisible"
      :visible="createVisible"
      :type="type"
      :place-type="placeType"
      :detail="detail"
      @close="createVisible = false"
      @reload="handleReloadDada"
      @reload-constant="handleReloadConstant"
    />
    <create-related-popup
      v-if="createRelatedVisible"
      :visible="createRelatedVisible"
      :type="type"
      :place-type="placeType"
      :detail="detail"
      @close="createRelatedVisible = false"
      @reload="handleReloadDada"
    />
    <create-children-popup
      v-if="childrenVisible"
      :visible="childrenVisible"
      :type="type"
      :place-type="placeType"
      :label="titles.find((t) => t.value === activeTab).label"
      :detail="detail"
      @close="childrenVisible = false"
      @reload="loadDataOfPage"
    />
    <tag-popup
      v-if="tagVisible"
      :visible="tagVisible"
      @close="tagVisible = false"
    />
    <filter-drawer
      ref="shared-filter"
      :visible="visibleFilter"
      :type="type"
      :place-type="placeType"
      :detail="detail"
      @close="visibleFilter = false"
      @filter="handleFilterData"
    ></filter-drawer>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters, mapActions } from 'vuex'

import TYPE from '@/data/type'

import { listPlaces, listPlaceRelated } from '@/services/place'
import { getComments } from '@/services/comment'

import SharedActions from './components/Actions.vue'
import TableColumn from './components/TableColumn'
import FilterDrawer from './components/Filter'
import SynchronizeButton from '../buttons/Synchronize'
import ExportExcelButton from '../buttons/ExportExcel.vue'
import ImportExcelButton from '../buttons/ImportExcel.vue'

import QrcodePopup from '../popups/Qrcode'
import CreateItemPopup from '../popups/CreateItem'
import CreateRelatedPopup from '../popups/CreateRelated'
import CreateChildrenPopup from '../popups/CreateChildren'
import TagPopup from '../popups/Tag'

import { getToShowRouter } from '@/utils/filters'

export default {
  name: 'SharedIndex',
  components: {
    SharedActions,
    TableColumn,
    FilterDrawer,
    SynchronizeButton,
    ExportExcelButton,
    ImportExcelButton,
    QrcodePopup,
    CreateItemPopup,
    CreateRelatedPopup,
    CreateChildrenPopup,
    TagPopup
  },
  props: {
    columns: Array,
    actions: Array,
    type: String,
    titles: Array,
    detail: Object,
    headerActions: Array,
    tab: Object,
    filterable: {
      type: Boolean,
      default: false
    },
    children: {
      type: Boolean,
      default: false
    },
    isRelated: {
      type: Boolean,
      default: false
    },
    eColumns: Array
  },
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      loading: true,
      searchText: '',
      tableData: [],
      order: '-created_at',
      selectedRow: null,
      qrVisible: false,
      createVisible: false,
      createRelatedVisible: false,
      childrenVisible: false,
      tagVisible: false,
      activeTab: this.titles && this.titles.length > 0 ? this.titles[0].value || '' : '',
      visibleFilter: false,
      filterParams: {}
    }
  },
  computed: {
    ...mapGetters(['language']),
    tableColumns() {
      return this.columns.map((c) =>
        TYPE.COLUMNS.find((column) => c === column.value)
      ).filter((c) => c.value !== 'parent' || this.children)
    },

    excelColumns() {
      return (this.eColumns || []).map((c) =>
        TYPE.EXCEL_COLUMNS.find((column) => c === column.key)
      )
    },

    tableHeaderActions() {
      return this.headerActions.map((a) => {
        if (this.detail && this.detail.id) {
          return TYPE.HEADER_ACTIONS.find((action) => a === action.key && !action.hide_detail)
        }
        return TYPE.HEADER_ACTIONS.find((action) => a === action.key)
      }).filter(Boolean)
    },

    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.type)
    },

    requestParams() {
      if (this.isRelated) {
        return {
          locale: this.language,
          page: this.page,
          per_page: this.limit,
          place_id: this.detail.id,
          name: this.searchText,
          place_type: this.type
        }
      }
      let params = {
        locale: this.language,
        page: this.page,
        per_page: this.limit,
        order: this.order,
        search_text: this.searchText,
        embedded: 'organization'
      }
      if (this.type === 'comment') {
        params = { ...params, approved: this.activeTab }
      } else {
        params = { ...params, place_types: this.type }
        if (!this.children) {
          params = { ...params, only_parent: true }
        } else {
          params = { ...params, only_children: true }
          if (this.detail && this.detail.type === this.type) {
            params = { ...params, parent_id: this.detail.id }
          }
        }
      }
      if (this.detail && this.detail.type !== this.type) {
        params = { ...params, [`${this.detail.type}_id`]: this.detail.id }
      }
      return {
        ...params,
        ...this.filterParams
      }
    }
  },
  watch: {
    activeTab: 'handleReloadDada',
    language: 'handleReloadDada'
  },
  beforeMount() {
    this.loadDataOfPage()
  },
  methods: {
    getToShowRouter,
    ...mapActions('public', ['updateReloadConstant']),

    loadDataOfPage(params = this.requestParams) {
      this.loading = true
      let request = null
      if (this.isRelated) {
        request = listPlaceRelated(params)
      } else if (this.type === 'comment') {
        request = getComments(params)
      } else {
        request = listPlaces(params)
      }
      request.then((response) => {
        this.tableData = []
        this.$nextTick(() => {
          let data = response.result
          if (this.isRelated) {
            data = data.map((d) => d.related_place)
          }
          this.tableData = data.map((r, index) => ({
            ...r,
            stt: index + 1 + (this.page - 1) * this.limit
          }))
        })
        this.total = response.total
        this.loading = false
      })
        .catch(() => {
          this.loading = false
        })
    },

    handleChangeSize(size) {
      this.limit = size
      this.page = 1
      this.loadDataOfPage()
    },

    handleChangePage(page) {
      this.page = page
      this.loadDataOfPage()
    },

    handleSearchText() {
      this.page = 1
      this.loadDataOfPage()
    },

    handleSortData(column) {
      this.order = `${column.order === 'descending' ? '-' : ''}${column.prop}`
      this.page = 1
      this.loadDataOfPage()
    },

    handleReloadDada() {
      if (this.children) {
        this.$emit('reload')
      }
      this.$refs['shared-filter'].handleResetForm()
      this.$nextTick(() => {
        this.page = 1
        this.searchText = ''
        this.order = '-created_at'
        this.loadDataOfPage()
      })
    },

    handleFilterData(filterParams) {
      if (this.children) {
        this.$emit('reload')
      }
      this.filterParams = filterParams
      this.$nextTick(() => {
        this.page = 1
        this.searchText = ''
        this.order = '-created_at'
        this.loadDataOfPage(this.requestParams)
      })
    },

    handleGetChildrens() {
      this.page = 1
      this.searchText = ''
      this.order = '-created_at'
      this.loadDataOfPage()
    },

    handleHeaderAction(button) {
      switch (button.key) {
        case 'add':
          if (this.children) {
            this.childrenVisible = true
          } else if (this.isRelated) {
            this.createRelatedVisible = true
          } else {
            this.createVisible = true
          }
          break
        case 'filter':
          this.visibleFilter = true
          break
        case 'reset':
          this.filterParams = {}
          this.handleReloadDada()
          break
        case 'cook':
          this.$router.push({ name: 'ManageCook' })
          break
        case 'cashier':
          this.$router.push({ name: 'ManageCashier' })
          break
        case 'tag':
          this.tagVisible = true
          break
        default:
          break
      }
    },

    pageTitle(currentTitle) {
      const title = this.titles.find((t) => t.value === currentTitle)
      return `${title.label} ${this.children ? 'CON' : ''} ${this.activeTab === currentTitle && !this.loading ? `(${this.total})` : ''}`
    },

    handleReloadConstant() {
      this.updateReloadConstant(dayjs().unix())
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
