<template>
  <div>
    <el-upload
      class="file-uploader"
      action
      :accept="`.json`"
      :show-file-list="false"
      :http-request="uploadFiles"
    >
      <el-button
        size="small"
        type="primary"
        icon="el-icon-upload2"
        :loading="callingAPI"
        :disabled="disabled"
      >
        Import
      </el-button>
    </el-upload>
    <import-excel-popup
      v-if="visible"
      :visible="visible"
      :total="total"
      :columns="columns"
      :type="type"
      :titles="titles"
      :active-tab="activeTab"
      :excel-data="excelData"
      :all-tags="allTags"
      :detail="detail"
      :children="children"
      @reload="() => $emit('reload')"
      @close="visible = false"
    />
  </div>
</template>

<script>
import ImportExcelPopup from '../popups/ImportExcel.vue'
import { getTags } from '@/services/tag'

export default {
  name: 'ImportExcelButton',
  components: {
    ImportExcelPopup
  },
  props: {
    total: Number,
    disabled: Boolean,
    columns: Array,
    type: String,
    titles: Array,
    detail: Object,
    children: Boolean,
    activeTab: [String, Boolean]
  },
  data() {
    return {
      visible: false,
      callingAPI: false,
      excelData: [],
      allTags: []
    }
  },
  computed: {},
  beforeMount() {
    this.loadAllTags()
  },
  methods: {
    loadAllTags() {
      getTags({ page: 1, per_page: 1000000, locale: 'vi' }).then((response) => {
        this.allTags = response.result
      }).catch(() => {
        this.allTags = []
      })
    },
    async uploadFiles(fileInfo) {
      const file = fileInfo.file
      this.callingAPI = true
      const reader = new FileReader()
      const self = this
      reader.onload = function(e) {
        const data = e.target.result
        self.excelData = JSON.parse(data)
        self.visible = true
        self.callingAPI = false
      }

      reader.onerror = function(ex) {
        console.log(ex)
      }

      reader.readAsText(file)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
