<template>
  <el-drawer
    :visible="visible"
    direction="ltr"
    size="350px"
    custom-class="filter-drawer"
    @close="$emit('close')"
  >
    <h3 slot="title">BỘ LỌC</h3>
    <el-form ref="form" :model="form">
      <p v-if="isShowPrice">Mức giá</p>
      <el-form-item v-if="isShowPrice" class="mb-0" prop="price">
        <el-radio-group v-model="form.price">
          <el-radio label="all">Tất cả</el-radio>
          <el-radio label="free">Miễn phí</el-radio>
          <el-radio label="range">Khoảng giá</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.price === 'range' && isShowPrice" prop="value">
        <p class="text-center my-1">
          <b>{{ form.value[0] }}</b> -
          <b>{{ form.value[1] }}</b>
        </p>
        <el-slider v-model="form.value" range :max="5000000"></el-slider>
      </el-form-item>
      <p>Danh mục</p>
      <el-form-item prop="categories">
        <el-checkbox-group v-model="form.categories">
          <el-checkbox
            v-for="category in categories"
            :key="category.id"
            :label="category.id"
          >{{ category.name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <p v-if="isShowArea">Thành phố</p>
      <el-form-item v-if="isShowArea" prop="areaId">
        <el-select v-model="form.areaId" placeholder="Chọn thành phố" class="w-100" filterable>
          <el-option v-for="area in areas" :key="area.id" :value="area.id" :label="area.name"></el-option>
        </el-select>
      </el-form-item>
      <p v-if="isShowArea">Đất nước</p>
      <el-form-item v-if="isShowArea" prop="countryId">
        <el-select v-model="form.countryId" placeholder="Chọn thành phố" class="w-100" filterable>
          <el-option
            v-for="country in countries"
            :key="country.id"
            :value="country.id"
            :label="country.name"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-button
      class="mt-3"
      type="primary"
      icon="el-icon-search"
      @click="handleSearchData"
    >Tìm kiếm theo bộ lọc</el-button>
  </el-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import TYPE from '@/data/type'

export default {
  name: 'Filter',
  props: {
    visible: Boolean,
    type: String,
    detail: Object
  },
  data() {
    return {
      form: {
        price: 'all',
        value: [1000000, 4000000],
        categories: [],
        areaId: '',
        countryId: ''
      }
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.type)
    },
    isShowArea() {
      if (this.detail) {
        return !['area', 'country'].includes(this.detail.type)
      }
      return !['area', 'country'].includes(this.type)
    },
    categories() {
      return this.constant.constants.place_categories.filter(
        (c) => c.place_type === this.type
      )
    },
    areas() {
      return this.constant.constants.areas
    },
    countries() {
      return this.constant.constants.countries
    },
    prarmas() {
      const p = {}
      if (this.form.price === 'free') {
        p.free = true
      } if (this.form.price === 'range') {
        p.free = false
        p.price_range = this.form.value.join(',')
      }
      if (this.form.categories.length) {
        p.category_ids = this.form.categories.join(',')
      }
      if (this.form.areaId) {
        p.area_id = this.form.areaId
      }
      if (this.form.countryId) {
        p.country_id = this.form.countryId
      }
      return p
    },
    isShowPrice() {
      return !['area', 'country', 'post', 'dashboard', 'kiosk', 'lookup'].includes(this.type)
    }
  },
  methods: {
    handleSearchData() {
      if (JSON.stringify(this.prarmas) !== '{}') {
        this.$emit('filter', this.prarmas)
      }
      this.$emit('close')
    },

    handleResetForm() {
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.filter-drawer {
  padding: 20px;
  overflow-y: auto;
  .el-drawer__header {
    padding: 0;
    margin-bottom: 10px;
    h3 {
      margin: 0;
    }
  }
  .el-checkbox {
    width: 100%;
  }
  .el-button {
    width: 100%;
    margin-bottom: 24px;
  }
}
</style>
